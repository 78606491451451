const OK = 200;
const FAILED = 1;
const NOT_AUTHRIZATIONED = 2;
const CONNECT_SERVER_FAILED = 3;
const ERROR_LOGIN_FAILED = 4;
const ERROR_USER_EXIST = 5;
const ERROR_USER_DISABLE = 6;
const ERROR_USER_EXPIRE = 7;
const ERROR_USER_LOCKED = 8;
const ERROR_PARAMS_INVALIDATE = 100;
const ERROR_PARAMS_EMPTY = 101;
const ERROR_ALREADY_EXIST = 102;
const ERROR_AUTH_NOT_PERMITTED = 103;


const status = {
    200: "",
    1: "操作失败",
    2: "没有访问权限",
    3: "连接服务器失败",
    4: "用户名或密码不正确",
    5: "用户名已存在",
    6: "账号未激活",
    7: "账号已过期",
    8: "账号已锁定",
    100: "参数不合法",
    101: "参数为空",
    102: "数据已经存在",
    103: "无操作权限",
};

export const getMessage = code => (status[code] || "未定义错误");

export default {
    OK,
    FAILED,
    NOT_AUTHRIZATIONED,
    CONNECT_SERVER_FAILED,
    ERROR_LOGIN_FAILED,
    ERROR_PARAMS_INVALIDATE,
    ERROR_USER_EXIST,
    ERROR_USER_DISABLE,
    ERROR_USER_EXPIRE,
    ERROR_USER_LOCKED,
    ERROR_PARAMS_EMPTY,
    ERROR_ALREADY_EXIST,
    ERROR_AUTH_NOT_PERMITTED,
    getMessage
}