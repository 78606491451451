import React from 'react'
import { Breadcrumb } from 'antd'

export default function XBreadcrumb(props) {

    return (
        <div>
            <Breadcrumb>
                {props.data.map(item => <Breadcrumb.Item key= {item}>{item}</Breadcrumb.Item>)}
            </Breadcrumb>
        </div>
    )
}