/* eslint-disable no-use-before-define */
import React from 'react'
import { Empty } from 'antd'


import '../../styles/textstyles.css'
import '../../styles/common.css'
import Apis from '../../api'
import Status from '../../api/Status'
import Storage from '../../util/Storage'
import LoginForm from './LoginForm'
import bg from '../../images/bg.png'



export default class AdminLoginContainer extends React.Component {
    state = {
        // userName: this.props.cookies.userName
        isError: false,
        msg: ""
    };

    static protoTypes = {
    };

    static defaultProps = {
    }

    onLogin = (userName, psw) => {
        console.log("===onlogin====");
        this.setState({
            isError: false
        });
        Apis.adminLogin(userName, psw)
            .then(data => {
                console.log(data);
                Storage.saveAuth(data.token);
                Storage.setUser(data.data);
                // localStorage.setItem("auth", data.token);
                const url = this.props.redirectTo;
                this.props.history.replace(url ? url : '/');
            }).catch(error => {
                this.setState({
                    isError: true,
                    msg: Status.getMessage(error.code)
                });
            })
    }

    render() {
        return (
            <div className="container login">
                {/*<label className="title" >云石电子菜谱<br />管理员系统</label>*/}

                <LoginForm
                    onLogin={this.onLogin}
                    isError={this.state.isError}
                    msg={this.state.msg} />
                <div className="bg-img">
                    <img src={bg}/>
                </div>
            </div>
        );
    }
}

