import React from 'react'
import { Route, Link, BrowserRouter, Switch } from 'react-router-dom'
import { Layout, Menu, Icon } from 'antd';

import MainHeader from '../../components/MainHeader'
import XBreadcrumb from '../../components/XBreadcrumb';
import Storage from '../../util/Storage'
import UserContainer from '../user/UserContainer';
import DeviceManageContailer from '../device/DeviceManageContainer';

const { Header, Sider, Content } = Layout;
export default class AdminMainContainer extends React.Component {

    token = Storage.getAuth();
    user = Storage.getUser();
    // token = localStorage.getItem("auth");

    state = {
        collapsed: false,
        breadcrumbs: ['账号管理']
    };

    toggle = () => {
        this.setState({
            ...this.state,
            collapsed: !this.state.collapsed,
        });
    }

    componentWillMount() {
        if (!this.user || !this.token) {
            this.props.history.replace('/login')
        }
    }

    onSelected = e => {
        console.log('onselected->' + e);
        this.setState({
            ...this.state,
            breadcrumbs: this.getBreadcrumb(e)
        });
    }

    getBreadcrumb = (e) => {
        return e.item.props.parent ? [e.item.props.parent, e.item.props.label] : [e.item.props.label];
    }


    render() {
        return (
            <BrowserRouter>
                <div className='h100'>
                    <MainHeader history={this.props.history} ></MainHeader>
                    <Layout className='content' >
                        <Sider
                            trigger={null}
                            collapsible
                            collapsed={this.state.collapsed}
                            className='mysider'
                        >
                            <div className="logo" />
                            <Menu className='mymenu' theme="light" mode="inline" onSelect={this.onSelected} defaultSelectedKeys={['user']}>
                                <Menu.Item key="user" label="账号管理">
                                    <Link to='/' style={{}}>
                                        <Icon type="shop" theme='filled' />
                                        <span>账号管理</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="device" label="设备授权">
                                    <Link to='/device' style={{}}>
                                        <Icon type="tablet" theme='filled' />
                                        <span>设备管理</span>
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Sider>·
                        <Layout className='right-content'>
                            <Header style={{ background: '#fff', padding: '0  0  0 25px' }}>
                                <Icon
                                    className="trigger"
                                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                    onClick={this.toggle}
                                />
                                <XBreadcrumb data={this.state.breadcrumbs} />
                            </Header>
                            <Content style={{
                                margin: '24px 16px 18px 16px', padding: 24, background: '#fff', minHeight: 280,
                            }}>
                                <Switch>
                                    <Route exact path='/' component={UserContainer}></Route>
                                    <Route path='/device' component={DeviceManageContailer}></Route>
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </BrowserRouter >
        );
    }
}