// import { Modal } from 'antd'

import Config from "./config"
import Status from './Status'
import CommonError from '../exception/CommonError'
import FetchError from '../exception/FetchError'

import Storage from '../util/Storage'

// const defaultErrorHandler = (code, msg) => {
//     console.log("error==>" + Status.getMessage(code));
//     Modal.warn({
//         title: "提示",
//         content: Status.getMessage(code),
//     })
// }
const xUploadSomething = (url, method, body) => {
    console.log('url==>' + url);
    console.log("request " + method + '==>' + body);
    return new Promise((resolve, reject) => {
        fetch(url,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                    'Authorization': Storage.getAuth()
                },
                method: method,
                mode: 'cors',
                body: body
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new FetchError(Status.FAILED.code);
                }
            })
            .then((jsonData) => {
                console.log("response ->" + JSON.stringify(jsonData));
                if (jsonData.code === Status.OK) {
                    resolve(jsonData);
                } else {
                    throw new CommonError(jsonData.code, jsonData.message);
                }
            })
            .catch(error => {
                reject({ code: error.code, msg: error.message });
                // if (onError) onError(error.code, error.message);
            })
    })
}
const xPost = (url, body) => {
    return xUploadSomething(url, "post", body);
}

const xPut = (url, body) => {
    return xUploadSomething(url, "put", body);
}

const xDelete = (url, body) => {
    return xUploadSomething(url, "delete", body);
}

const xGet = (url) => {
    console.log('request(get) ==>' + url);
    return new Promise((resolve, reject) => {
        fetch(url,
            {
                headers: {
                    // "Content-Type": "application/json",
                    // 'Accept': 'application/json',
                    'Authorization': Storage.getAuth()
                },
                method: 'get',
                mode: 'cors'
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new FetchError(Status.FAILED.code);
                }
            })
            .then((jsonData) => {
                if (jsonData.code === Status.OK) {
                    resolve(jsonData);
                }
                else {
                    throw new CommonError(jsonData.code, jsonData.message);
                }
            })
            .catch(error => {
                reject({ code: error.code, msg: error.message });
            })
    })
}

//======admin ======

const getUsers = (pageInfo, filters = {}) => {
    console.log("filters=>" + JSON.stringify(filters));
    var url = Config.URL_ROOT + "users/";
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        if (filters) {
            Object.keys(filters).forEach(key => {
                const value = filters[key];
                if (value != null) params.push(key + "=" + value);
            });
        }
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const adminLogin = (userName, psw) => {
    return xPost(Config.URL_ROOT + "auth/adminSignin",
        JSON.stringify({ "userName": userName, "psw": psw }),
    )
}

const activeUser = (data) => {
    return xPut(Config.URL_ROOT + "users/active/" + data.id, JSON.stringify(data));
}

const blockUser = (data) => {
    return xPut(Config.URL_ROOT + "users/block/" + data.id, JSON.stringify(data));
}


const deleteUser = (data) => {
    return xDelete(Config.URL_ROOT + "users/" + data.id, JSON.stringify(data));
}

const getDeviceDetails = (pageInfo, userId) => {
    var url = Config.URL_ROOT + "devices/detail/" + userId;
    let params = [];
    if (pageInfo != null) {
        Object.keys(pageInfo).forEach(key => { params.push(key + "=" + pageInfo[key]) });
        url = url + "?" + params.join("&");
    }

    return xGet(url);
}

const activeDevice = (deviceId, startTime, endTime) => {
    return xPut(Config.URL_ROOT + "devices/active/" + deviceId, JSON.stringify({
        "startTime": startTime,
        "endTime": endTime,
    }));
}

const blockDevice = (id) => {
    return xPut(Config.URL_ROOT + "devices/block/" + id, "");
}

export default {
    getUsers,
    adminLogin,
    activeUser,
    blockUser,
    deleteUser,
    getDeviceDetails,
    activeDevice,
    blockDevice,
}