import React from 'react'

import {
    Form, Icon, Input, Button
} from 'antd';

import './login.css'
import title from '../../images/title.png'

const FormItem = Form.Item;

export default class LoginForm extends React.Component {

    handleSubmit = (e) => {

        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("submit->" + JSON.stringify(values));
                this.props.onLogin(values.userName, values.psw
                )
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="loginContent">
                <div className="up-title">
                    <div className="title-img">
                        <img src={title}/>
                    </div>
                    <div className="title-font">
                        <span>河北云石科技有限公司</span>
                    </div>
                </div>
                <div style={{width:"100%", marginTop: "15%"}}>
                    {/*<div style={{fontWeight:"bold", fontSize: "16px", marginBottom: "10px"}}>管理员登录</div>*/}
                    <label style={{ display: this.props.isError ? 'visible' : 'invisible', textAlign: 'left', color: 'red' }}>{this.props.msg}</label>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem className="formItem">
                            {getFieldDecorator('userName', {
                                rules: [{ required: true, message: '请输入用户名' }],
                            })(
                              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} defaultValue=".userName " />} placeholder="用户名" />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('psw', {
                                rules: [{ required: true, message: '请输入密码' }],
                            })(
                              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password"
                                     placeholder="密码" />
                            )}
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" className="login-form-button submit">
                                登录
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div >
        );
    }
}

LoginForm = Form.create()(LoginForm);
