import React from 'react'
import { Table, message, Modal, Divider, Popconfirm, Pagination, Button } from 'antd'
import moment from 'moment';

import MainToolbar from '../../components/MainToolbar';

import Apis from '../../api'
import '../../styles/pagination.css'
import PageInfo from '../../pages/Paging'
import Status from '../../api/Status';
import config from '../../api/config';
import MyEmpty from '../../components/MyEmpty'

const format = 'YYYY-MM-DD HH:mm:ss';
export default class UserContainer extends React.Component {
    state = {
        isEditModal: false,
        addModalVisible: false,
        loading: false,
        filter: null,
    }

    filterData = [
        {
            id: "0",
            name: "未激活"
        },
        {
            id: "1",
            name: "已激活"
        },
        {
            id: "2",
            name: "已过期"
        },
        {
            id: "3",
            name: "已锁定"
        },
    ]

    columns = [
        {
            title: "序号",
            dataIndex: "_index",
            render: (data, record, index) => {
                return index + 1;
            }
        },

        {
            title: "账号",
            dataIndex: "userName",
        },
        {
            title: "酒店名称",
            dataIndex: "name",
        },
        {
            title: "联系人",
            dataIndex: "contact",
        },
        {
            title: "联系电话",
            dataIndex: "phoneNum",
        },
        {
            title: "地址",
            dataIndex: "address",
        }, {
            title: "状态",
            render: (data, record, index) => {

                if (record.status === 0) {
                    return <span style={{ color: "red" }}>未激活</span>;
                } else if (record.status === 1) {
                    return <span style={{ color: "green" }}>已激活</span>;
                } else if (record.status === 2) {
                    return <span style={{ color: "gray" }}>已过期</span>;
                } else if (record.status === 3) {
                    return <span style={{ color: "gray" }}>已锁定</span>;
                } else {
                    return <span style={{ color: "red" }}>未知</span>;
                }
            },
        },
        {
            title: "上次登录时间",
            dataIndex: "lastLoginTime",
            render: (data, record, index) => {
                if (record.lastLoginTime) {
                    return moment(record.lastLoginTime ).format(format);
                } else {
                    return '--';
                }
            }
        },
        {
            title: '操作',
            width: "210px",
            dataIndex: 'operation',
            render: (data, record, index) => (
                record
                    ? (
                        <span>
                            {record.status !== 1 &&
                                <span>
                                    <Button
                                        size="small"
                                        type='primary'
                                        onClick={() => this.activeUser(record)}
                                    > 激活
                            </Button>
                                    <Divider type="vertical" />
                                </span>
                            }

                            {
                                record.status === 1 &&
                                <span>

                                    <Popconfirm title="确定禁用该用户?" okText="禁用" okType="danger" cancelText="取消" onConfirm={(e) => {
                                        e.preventDefault();
                                        this.blockItem(record);
                                    }
                                    }
                                    >
                                        <Button
                                            size="small"
                                        > 禁用
                                        </Button>
                                    </Popconfirm >
                                    <Divider type="vertical" />
                                </span>}


                            <Popconfirm title="确定删除该用户?" okText="删除" okType="danger" cancelText="取消" onConfirm={(e) => {
                                e.preventDefault();
                                this.deleteItem(record);
                            }
                            }
                            >
                                <Button
                                    size="small"
                                    type='danger'
                                > 删除
                                        </Button>
                            </Popconfirm >
                        </span>
                    ) : null
            ),
        }
    ]

    activeUser = (item) => {
        this.setState({
            ...this.state,
            loading: true
        });
        Apis.activeUser(item)
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(e => {
                message.error(Status.getMessage(e.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    }


    editItem = (data) => {
        this.setState({
            ...this.state,
            loading: true
        });

        Apis.updateDevice(data)
            .then(data => {
                this.setState({
                    ...this.state,
                    addModalVisible: false,
                    loading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });

    }

    blockItem = (item) => {
        Apis.blockUser(item)
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    }

    deleteItem = (item) => {
        Apis.deleteUser(item)
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    }

    onPageChange = (pageIndex) => {
        console.log("onPageChange=>" + pageIndex);
        this.loadPage(pageIndex - 1);

    }

    loadPage = (pageIndex) => {
        this.setState({
            ...this.state,
            loading: true
        });

        Apis.getUsers(PageInfo.getPage(pageIndex), this.state.filter)
            .then(data => {
                console.log("users->" + JSON.stringify(data.data));
                this.setState({
                    ...this.state,
                    loading: false,
                    data: data.data,
                    totalElements: data.totalElements,
                    currentPage: pageIndex + 1
                })
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false
                })
            })
    }

    selectFilterChanged = (status) => {
        if (config.KEY_ALL == status) {
            this.setState({
                ...this.state,
                filter: null
            }, () => this.loadPage(0));
        } else {
            this.setState({
                ...this.state,
                filter: { 'status': status }
            }, () => this.loadPage(0));
        }
    }

    componentDidMount() {
        this.loadPage(0);
    }

    render() {
        const { loading } = this.state;
        return (

            <div>
                <MainToolbar

                    showFilter={true}
                    selectData={this.filterData}
                    showAdd={false}
                    showSearch={false}
                    showBatchDelete={false}
                    selectTitle="商家账号状态"
                    showAllSelectItem={true}
                    selectFilterChanged={this.selectFilterChanged}
                />

                <Table pagination={false}
                    rowKey={'_index'}
                    locale={{ emptyText: <MyEmpty /> }}
                    columns={this.columns}
                    dataSource={this.state.data}
                    bordered
                    loading={loading} />
                <Pagination
                    showTotal={total => `总共 ${total} 条数据`}
                    current={this.state.currentPage}
                    onChange={this.onPageChange}
                    total={this.state.totalElements}
                    className="right" />

                <Modal
                    title="确认"
                    okText="删除"
                    okType="danger"
                    cancelText="取消"
                    visible={this.state.deleteConfirmModalVisible}
                    onCancel={() => this.setState({
                        ...this.state,
                        deleteConfirmModalVisible: false
                    })}
                    onOk={this.handleConfirmDeleteOk}
                >
                    <p>确认删除已选中的记录吗？</p>
                </Modal>
            </div>
        )
    }
}