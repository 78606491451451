import 'react-app-polyfill/ie9';
import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './index.css';

import * as serviceWorker from './serviceWorker';
import AdminLoginContainer from './containers/login/AdminLoginContainer';
import AdminMainContainer from './containers/main/AdminMainContainer';
import Config from './api/config'

(function () {
    const oldLog = console.log;
    const DEBUG = Config.DEBUG;
    console.log = (...optionalParams) => {
        if (DEBUG) {
            oldLog(...optionalParams);
        }
    };
})();


ReactDOM
    .render(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={AdminMainContainer} />
                <Route exact path="/login" component={AdminLoginContainer} />

                <Route component={AdminMainContainer} />
            </Switch>
        </BrowserRouter>,
        document.getElementById('root')
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
