export default class Paging {
    
    constructor() {
        this.pageIndex = 0;
        this.perPageSize = 10;
    }

    static getPage(pageIndex, perPageSize=10) {
        const page = new Paging();
        page.pageIndex = pageIndex;
        page.perPageSize = perPageSize;
        return page;
    }
}