import React from 'react';
import { Modal, DatePicker, Button, message } from 'antd';
import moment from 'moment';

const RangePicker = DatePicker.RangePicker;
export default class ChoseDurationModal extends React.Component {

    state = {
        startDate: null,
        endDate: null,
    }

    onChange = (dates, dateStrings) => {
        this.setState({
            startDate: dates[0] && dates[0].valueOf(),
            endDate: dates[1] && dates[1].valueOf(),

        });
    }

    handleOk = () => {
        if (!this.state.startDate || !this.state.endDate) {
            message.error("请选择授权日期");
            return;
        }

        this.props.onOk(this.state.startDate, this.state.endDate);
    }

    render() {
        const { visible, onCancel, upLoading } = this.props;
        if (!visible) return null;
        var startDate = this.props.startDate;
        var endDate = this.props.endDate;
        console.log("start->" + startDate + "    end->" + endDate);

        return (
            <Modal visible={true}
                title={"请选择"}
                width={'550px'}
                onOk={this.handleOk}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="submit" type="primary" loading={upLoading} onClick={this.handleOk}>确定</Button>
                ]}
            >
                <span style={{ fontWeight: "bold" }}>授权日期：</span>
                {
                    startDate && <RangePicker
                        placeholder={["激活日期", "过期日期"]}
                        format="YYYY/MM/DD"
                        defaultValue={[moment(startDate), moment(endDate)]}
                        onChange={this.onChange}
                    />
                }

                {
                    !startDate && <RangePicker
                        placeholder={["激活日期", "过期日期"]}
                        format="YYYY/MM/DD"
                        onChange={this.onChange}
                    />
                }

            </Modal>
        );
    }
}