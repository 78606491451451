import React from 'react'
import { Table, Pagination, message, Divider, Popconfirm, Button } from 'antd'
import moment from 'moment';

import MainToolbar from '../../components/MainToolbar'
import Apis from '../../api'
import PageInfo from '../../pages/Paging'
import '../../styles/pagination.css'
import Status from '../../api/Status';
import ChoseDurationModal from '../../components/ChoseDurationModal';
import MyEmpty from '../../components/MyEmpty'

const format = 'YYYY/MM/DD';
export default class DeviceManageContailer extends React.Component {

    state = {
        isEditModal: false,
        showActiveModal: false,
        upLoading: false,
        loading: false,
    }

    columns = [
        {
            title: "序号",
            dataIndex: "_index",
            render: (data, record, index) => {
                return index + 1;
            }
        },

        {
            title: "别名",
            dataIndex: "alias",
        },
        {
            title: "序列号",
            dataIndex: "serialNum",
        }, {
            title: "商家名称",
            dataIndex: "userName",
        },
        {
            title: "激活日期",
            dataIndex: "startTime",
            render: (data, record, index) => {
                if (record.startTime) {
                    return moment(record.startTime).format(format);
                } else {
                    return '--';
                }
            }
        },
        {
            title: "过期日期",
            dataIndex: "expireTime",
            render: (data, record, index) => {
                if (record.expireTime) {
                    return moment(record.expireTime).format(format);
                } else {
                    return '--';
                }
            }
        },
        {
            title: "状态",
            render: (data, record, index) => {
                if (record.expireTime) {
                    if (this.isBeforeNow(record.expireTime)) {
                        return <span style={{ color: 'red' }}>已过期</span>;
                    } else {
                        if (this.isBeforeNow(record.startTime)) {
                            return <span style={{ color: 'green' }}>有效</span>;
                        } else {
                            return <span style={{ color: "blue" }}>未到期</span>;

                        }
                    }
                } else {
                    return <span style={{ color: "red" }}>未激活</span>;
                }
            }
        },
        {
            title: '操作',
            width: "210px",
            dataIndex: 'operation',
            render: (data, record, index) => (
                record
                    ? (
                        <span>
                            <Button
                                size="small"
                                type='primary'
                                onClick={() => this.activeDevice(record)}
                            > 授权
                            </Button>
                            <Divider type="vertical" />

                            {
                                record.expireTime &&
                                <Popconfirm title="确定撤销该设备授权?" okText="撤销" okType="danger" cancelText="取消" onConfirm={(e) => {
                                    e.preventDefault();
                                    this.blockItem(record);
                                }
                                }
                                >
                                    <Button
                                        size="small"
                                        type='danger'

                                    > 撤销
                                        </Button>
                                </Popconfirm >
                            }
                            {
                                !record.expireTime &&

                                <Button
                                    size="small"
                                    type='danger'
                                    disabled

                                > 撤销
                                </Button>
                            }
                        </span>
                    ) : null
            ),
        }
    ];

    activeDevice = (device) => {
        this.setState({
            ...this.state,
            editItem: device,
            showActiveModal: true
        })
    }

    activeDeviceOnOk = (startTime, endTime) => {
        this.setState({
            ...this.state,
            upLoading: true
        });

        Apis.activeDevice(this.state.editItem.id, startTime, endTime)
            .then(data => {
                this.setState({
                    ...this.state,
                    editItem: null,
                    showActiveModal: false,
                    upLoading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    upLoading: false,
                });
            });
    }

    isBeforeNow = (time) => {
        return moment(new Date(time)).isBefore(moment());
    }

    blockItem = (item) => {
        this.setState({
            ...this.state,
            loading: true
        })
        Apis.blockDevice(item.id)
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
                this.loadPage(this.state.currentPage - 1);
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    }

    onCancel = () => {
        this.setState({
            ...this.state,
            upLoading: false,
            editItem: null,
            showActiveModal: false
        });

    }

    onPageChange = (pageIndex) => {
        this.loadPage(pageIndex - 1);
    }

    loadPage = (pageIndex) => {
        this.setState({
            ...this.state,
            loading: true
        });

        Apis.getDeviceDetails(PageInfo.getPage(pageIndex), this.state.userId)
            .then(data => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: data.data,
                    totalElements: data.totalElements,
                    currentPage: pageIndex + 1
                })
            })
            .catch(error => {
                message.error(Status.getMessage(error.code));
                this.setState({
                    ...this.state,
                    loading: false
                })
            })
    }

    userChanged = (userId) => {
        this.setState({
            ...this.state,
            userId: userId
        }, () => this.loadPage(0));
    }

    componentWillMount() {
        Apis.getUsers(PageInfo.getPage(0, 9999), { "status": 1 })
            .then(data => {
                this.setState({
                    ...this.state,
                    users: data.data
                });
            })
            .catch(e => {
                message.error(Status.getMessage(e.code));
            })
    }

    render() {
        console.log('time->' + (this.state.editItem && this.state.editItem.startTime));
        console.log("user->" + JSON.stringify(this.state.users));
        const { loading } = this.state;
        return (
            <div>
                <MainToolbar
                    showFilter={true}
                    showAdd={false}
                    showAllSelectItem={false}
                    selectData={this.state.users}
                    selectFilterChanged={this.userChanged}
                    showBatchDelete={false}
                    showSearch={false}
                    selectTitle={"选择商家"} />
                <Table pagination={false}
                locale={{ emptyText: <MyEmpty /> }}
                    rowKey={'_index'}
                    columns={this.columns}
                    dataSource={this.state.data}
                    bordered
                    loading={loading} />
                <Pagination
                    current={this.state.currentPage}
                    onChange={this.onPageChange}
                    total={this.state.totalElements}
                    className="right"
                    showTotal={total => `总共 ${total} 条数据`}
                />

                <ChoseDurationModal
                    visible={this.state.showActiveModal}
                    startDate={this.state.editItem && this.state.editItem.startTime}
                    endDate={this.state.editItem && this.state.editItem.expireTime}
                    onOk={this.activeDeviceOnOk}
                    onCancel={this.onCancel}
                />
            </div>
        )
    }
}