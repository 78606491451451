export default class Storage {

    static setUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    static clearUser() {
        localStorage.removeItem('user');
    }

    static getUser = () => JSON.parse(localStorage.getItem('user'));

    static saveAuth(auth) {
        localStorage.setItem('auth', "Bearer " + auth);
    }

    static clearAuth() {
        localStorage.removeItem('auth');
    }

    static getAuth = () => localStorage.getItem('auth');
}